<template>
  <b-card-code
    no-body
    title="Topup"
  >
    <TopupList
      :result="result"
      :is-loading="isLoading"
      :get-topup-data="getData"
      :link="'talenta.topup-ads'"
      :type-topup="'ads'"
      :talent-or-user="'talenta'"
      :endpointTalentOrUser="'talents'"
      @filter="getFilter($event)"
    />
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import TopupList from '@/components/Wallet/TopupList.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return `Topup List`
  },
  components: {
    BCardCode,
    TopupList,
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        talent_uuid: '',
        keyword: '',
      },
      isLoading: false,
      formPayload: Object,
      validations: '',
      editId: null,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getFilter(value) {
      this.filter = value || ''
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/wallet/ads/topup', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>
